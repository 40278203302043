import React from 'react'

import { Helmet } from 'react-helmet'

import Pricing6 from '../components/pricing6'
import Logos11 from '../components/logos11'
import Features11 from '../components/features11'
import FAQ11 from '../components/faq11'
import Navbar from "../components/Navbar/Navbar";
import './pricing.css'
import Footer from '../components/Footer/Footer'

const Pricing = (props) => {
  return (
    <div className="page-container">
      <Helmet>
        <title>Pricing</title>
        <meta property="og:title" content="Pricing" />
      </Helmet>
      <Navbar />
      <div className="home-hero">
        <Pricing6></Pricing6>
      </div>
      <div className="page-logos3">
        <Logos11></Logos11>
      </div>
      <div className="page-features4">
        <Features11></Features11>
      </div>
      <div className="page-faq5">
        <FAQ11></FAQ11>
      </div>
      <Footer />
    </div>
  )
}

export default Pricing
