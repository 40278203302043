import React from 'react'

import PropTypes from 'prop-types'

import './features23.css'

const Features23 = (props) => {
  return (
    <div className="features23-layout349 thq-section-padding">
      <div className="features23-max-width thq-section-max-width">
        <div className="features23-container">
          <h2 className="thq-heading-2">{props.heading1}</h2>
          <span className="features23-text01 thq-body-small">
            {props.content1}
          </span>
          <button className="thq-button-filled features23-button">
            <span className="thq-body-small">{props.action1}</span>
          </button>
        </div>
        <div className="thq-grid-3">
          <div className="features23-container2 thq-card">
            <img
              alt={props.feature1ImageAlt}
              src={props.feature1ImageSrc}
              className="features23-image thq-img-round"
            />
            <h2 className="thq-heading-2">{props.feature1Title}</h2>
            <span className="features23-text03 thq-body-small">
              {props.feature1Description}
            </span>
          </div>
          <div className="features23-container3 thq-card">
            <img
              alt={props.feature2ImageAlt}
              src={props.feature2ImageSrc}
              className="features23-image1 thq-img-round"
            />
            <h2 className="thq-heading-2">{props.feature2Title}</h2>
            <span className="features23-text05 thq-body-small">
              {props.feature2Description}
            </span>
          </div>
          <div className="features23-container4 thq-card">
            <img
              alt={props.feature3ImageAlt}
              src={props.feature3ImageSrc}
              className="features23-image2 thq-img-round"
            />
            <h2 className="thq-heading-2">{props.feature3Title}</h2>
            <span className="features23-text07 thq-body-small">
              {props.feature3Description}
            </span>
          </div>
          <div className="features23-container5 thq-card">
            <img
              alt={props.feature4ImageAlt}
              src={props.feature4ImageSrc}
              className="features23-image3 thq-img-round"
            />
            <h2 className="thq-heading-2">{props.feature4Title}</h2>
            <span className="features23-text09 thq-body-small">
              {props.feature4Description}
            </span>
          </div>
          <div className="features23-container6 thq-card">
            <img
              alt={props.feature5ImageAlt}
              src={props.feature5ImageSrc}
              className="features23-image4 thq-img-round"
            />
            <h2 className="thq-heading-2">{props.feature5Title}</h2>
            <span className="features23-text11 thq-body-small">
              {props.feature5Description}
            </span>
          </div>
          <div className="features23-container7 thq-card">
            <img
              alt={props.feature6ImageAlt}
              src={props.feature6ImageSrc}
              className="features23-image5 thq-img-round"
            />
            <h2 className="thq-heading-2">{props.feature6Title}</h2>
            <span className="features23-text13 thq-body-small">
              {props.feature6Description}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

Features23.defaultProps = {
  feature6ImageAlt: 'Effortless Optimization Image',
  feature3Title: 'Financial Goal Setting',
  feature2ImageSrc: 'https://play.teleporthq.io/static/svg/default-img.svg',
  feature4Description:
    'Generate detailed reports to analyze your financial habits and make informed decisions.',
  content1:
    'Explore the features that make AlgoFunds the perfect platform for managing your personal finances.',
  feature4ImageSrc:
    'https://images.unsplash.com/photo-1614064643087-96ce7f0737c8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNjg4MDU0N3w&ixlib=rb-4.0.3&q=80&w=1080',
  feature2Title: 'Budget Management',
  feature6Title: 'Effortless Optimization',
  feature1ImageAlt: 'Expense Tracking Image',
  feature5Description:
    'Receive personalized recommendations to make smarter money choices and improve your financial health.',
  feature6Description:
    "Optimize your finances seamlessly with AlgoFunds' intuitive tools and features.",
  action1: 'Get Started',
  feature3Description:
    'Define your financial goals and track your progress towards achieving them.',
  feature4ImageAlt: 'Advanced Reports Image',
  feature3ImageAlt: 'Financial Goal Setting Image',
  feature2Description:
    'Set budgets for different categories and monitor your spending to stay on track.',
  feature2ImageAlt: 'Budget Management Image',
  feature5ImageSrc:
    'https://images.unsplash.com/photo-1624844869348-41c84052d977?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNjg4MDU0OHw&ixlib=rb-4.0.3&q=80&w=1080',
  feature1Description:
    'Effortlessly track your expenses and categorize them for better financial visibility.',
  heading1: 'Features',
  feature5ImageAlt: 'Smart Money Decisions Image',
  feature1Title: 'Expense Tracking',
  feature1ImageSrc:
    'https://images.unsplash.com/photo-1532619675605-1ede6c2ed2b0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNjg4MDU0OHw&ixlib=rb-4.0.3&q=80&w=1080',
  feature6ImageSrc:
    'https://images.unsplash.com/photo-1460925895917-afdab827c52f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNjg4MDU0OXw&ixlib=rb-4.0.3&q=80&w=1080',
  feature5Title: 'Smart Money Decisions',
  feature3ImageSrc: 'https://play.teleporthq.io/static/svg/default-img.svg',
  feature4Title: 'Advanced Reports',
}

Features23.propTypes = {
  feature6ImageAlt: PropTypes.string,
  feature3Title: PropTypes.string,
  feature2ImageSrc: PropTypes.string,
  feature4Description: PropTypes.string,
  content1: PropTypes.string,
  feature4ImageSrc: PropTypes.string,
  feature2Title: PropTypes.string,
  feature6Title: PropTypes.string,
  feature1ImageAlt: PropTypes.string,
  feature5Description: PropTypes.string,
  feature6Description: PropTypes.string,
  action1: PropTypes.string,
  feature3Description: PropTypes.string,
  feature4ImageAlt: PropTypes.string,
  feature3ImageAlt: PropTypes.string,
  feature2Description: PropTypes.string,
  feature2ImageAlt: PropTypes.string,
  feature5ImageSrc: PropTypes.string,
  feature1Description: PropTypes.string,
  heading1: PropTypes.string,
  feature5ImageAlt: PropTypes.string,
  feature1Title: PropTypes.string,
  feature1ImageSrc: PropTypes.string,
  feature6ImageSrc: PropTypes.string,
  feature5Title: PropTypes.string,
  feature3ImageSrc: PropTypes.string,
  feature4Title: PropTypes.string,
}

export default Features23
