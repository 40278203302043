import React from 'react'

import PropTypes from 'prop-types'

import './stats1.css'

const Stats1 = (props) => {
  return (
    <div className="thq-section-padding">
      <div className="stats1-max-width thq-section-max-width">
        <div className="stats1-container1 thq-flex-column">
          <span className="thq-body-small">{props.content1}</span>
          <h2 className="thq-heading-2">{props.heading1}</h2>
          <p className="thq-body-large">{props.content2}</p>
          <div className="stats1-container2">
            <div className="stats1-container3">
              <h2 className="thq-heading-2">{props.stat1}</h2>
              <span className="thq-body-small">{props.stat1Description}</span>
            </div>
            <div className="stats1-container4">
              <h2 className="thq-heading-2">{props.stat2}</h2>
              <span className="thq-body-small">{props.stat2Description}</span>
            </div>
          </div>
          <div className="stats1-container5">
            <div className="stats1-container6">
              <h2 className="thq-heading-2">{props.stat3}</h2>
              <span className="thq-body-small">{props.stat3Description}</span>
            </div>
            <div className="stats1-container7">
              <h2 className="thq-heading-2">{props.stat4}</h2>
              <span className="thq-body-small">{props.stat4Description}</span>
            </div>
          </div>
        </div>
        <div className="stats1-container8">
          <img
            alt={props.image1Alt}
            src={props.image1Src}
            className="thq-img-ratio-1-1 stats1-image"
          />
        </div>
      </div>
    </div>
  )
}

Stats1.defaultProps = {
  stat1: '80%',
  stat1Description: 'Financial Success Rate',
  stat4: '100%',
  stat3Description: 'Personal Finance Management',
  stat2Description: 'Investment Growth Rate',
  stat2: '90%',
  image1Alt: 'Statistics Image',
  stat4Description: 'Customer Satisfaction Rate',
  stat3: '95%',
  heading1: 'Key Statistics',
  content1:
    'Discover some key statistics that showcase the impact AlgoFunds has had on personal finance management.',
  image1Src: 'https://play.teleporthq.io/static/svg/default-img.svg',
  content2:
    'Join AlgoFunds today and start your journey towards financial success!',
}

Stats1.propTypes = {
  stat1: PropTypes.string,
  stat1Description: PropTypes.string,
  stat4: PropTypes.string,
  stat3Description: PropTypes.string,
  stat2Description: PropTypes.string,
  stat2: PropTypes.string,
  image1Alt: PropTypes.string,
  stat4Description: PropTypes.string,
  stat3: PropTypes.string,
  heading1: PropTypes.string,
  content1: PropTypes.string,
  image1Src: PropTypes.string,
  content2: PropTypes.string,
}

export default Stats1
