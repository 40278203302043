import React from 'react'

import PropTypes from 'prop-types'

import './testimonial16.css'

const Testimonial16 = (props) => {
  return (
    <div className="thq-section-padding">
      <div className="testimonial16-max-width thq-section-max-width">
        <div className="testimonial16-container">
          <h2 className="thq-heading-2">{props.heading1}</h2>
          <span className="testimonial16-text01 thq-body-small">
            {props.content1}
          </span>
        </div>
        <div className="thq-grid-2">
          <div className="testimonial16-container02 thq-card">
            <div className="testimonial16-container03">
              <img
                alt={props.author1Alt}
                src={props.author1Src}
                className="testimonial16-image"
              />
              <div className="testimonial16-container04">
                <strong className="thq-body-large">{props.author1Name}</strong>
                <span className="thq-body-small">{props.author1Position}</span>
              </div>
            </div>
            <span className="testimonial16-text04 thq-body-small">
              {props.review1}
            </span>
          </div>
          <div className="testimonial16-container05 thq-card">
            <div className="testimonial16-container06">
              <img
                alt={props.author2Alt}
                src={props.author2Src}
                className="testimonial16-image1"
              />
              <div className="testimonial16-container07">
                <strong className="thq-body-large">{props.author2Name}</strong>
                <span className="thq-body-small">{props.author2Position}</span>
              </div>
            </div>
            <span className="testimonial16-text07 thq-body-small">
              {props.review2}
            </span>
          </div>
          <div className="testimonial16-container08 thq-card">
            <div className="testimonial16-container09">
              <img
                alt={props.author3Alt}
                src={props.author3Src}
                className="testimonial16-image2"
              />
              <div className="testimonial16-container10">
                <strong className="thq-body-large">{props.author3Name}</strong>
                <span className="thq-body-small">{props.author3Position}</span>
              </div>
            </div>
            <span className="testimonial16-text10 thq-body-small">
              {props.review3}
            </span>
          </div>
          <div className="testimonial16-container11 thq-card">
            <div className="testimonial16-container12">
              <img
                alt={props.author4Alt}
                src={props.author4Src}
                className="testimonial16-image3"
              />
              <div className="testimonial16-container13">
                <strong className="thq-body-large">{props.author4Name}</strong>
                <span className="thq-body-small">{props.author4Position}</span>
              </div>
            </div>
            <span className="testimonial16-text13 thq-body-small">
              {props.review4}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

Testimonial16.defaultProps = {
  review1:
    "AlgoFunds has completely transformed the way I manage my finances. It's intuitive, easy to use, and has helped me reach my savings goals faster than I ever imagined.",
  author2Src:
    'https://images.unsplash.com/photo-1563584316028-2b70b3a3a8bf?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNjg4MDU0N3w&ixlib=rb-4.0.3&q=80&w=1080',
  author2Name: 'Alia khan',
  author4Name: 'Simran Patel',
  author4Src:
    'https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNjg4MDU0N3w&ixlib=rb-4.0.3&q=80&w=1080',
  heading1: 'Testimonials',
  author3Name: 'Kaushta Sharma',
  author3Position: 'Marketing Manager',
  review2:
    "I've tried several budgeting apps before, but AlgoFunds stands out for its simplicity and effectiveness. I highly recommend it to anyone looking to take control of their money.",
  author4Alt: 'image',
  review4:
    'AlgoFunds has made budgeting less of a chore and more of a habit. Thanks to its features, I now have a clear picture of where my money is going and how I can save more.',
  review3:
    "As a busy professional, I needed a tool that could streamline my financial tracking. AlgoFunds not only met but exceeded my expectations. It's a game-changer!",
  author4Position: 'Software Engineer',
  author1Alt: 'image',
  author2Position: 'Small Business Owner',
  author1Name: 'Sidharth Mehta',
  author1Src:
    'https://images.unsplash.com/flagged/photo-1578025468422-e69c574eb76a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNjg4MDU0OHw&ixlib=rb-4.0.3&q=80&w=1080',
  author3Alt: 'image',
  content1: 'See what our users have to say about their experience with AlgoFunds.',
  author1Position: 'Freelancer',
  author3Src:
    'https://images.unsplash.com/photo-1636041247924-17cced2e0fd4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNjg4MDU0OHw&ixlib=rb-4.0.3&q=80&w=1080',
  author2Alt: 'image',
}

Testimonial16.propTypes = {
  review1: PropTypes.string,
  author2Src: PropTypes.string,
  author2Name: PropTypes.string,
  author4Name: PropTypes.string,
  author4Src: PropTypes.string,
  heading1: PropTypes.string,
  author3Name: PropTypes.string,
  author3Position: PropTypes.string,
  review2: PropTypes.string,
  author4Alt: PropTypes.string,
  review4: PropTypes.string,
  review3: PropTypes.string,
  author4Position: PropTypes.string,
  author1Alt: PropTypes.string,
  author2Position: PropTypes.string,
  author1Name: PropTypes.string,
  author1Src: PropTypes.string,
  author3Alt: PropTypes.string,
  content1: PropTypes.string,
  author1Position: PropTypes.string,
  author3Src: PropTypes.string,
  author2Alt: PropTypes.string,
}

export default Testimonial16
