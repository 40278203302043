import React from 'react'

import PropTypes from 'prop-types'

import './content-list1.css'

const ContentList1 = (props) => {
  return (
    <div className="content-list1-container thq-section-padding">
      <div className="content-list1-max-width thq-section-max-width">
        <div className="content-list1-content thq-flex-column">
          <ul className="thq-flex-column">
            <li className="list-item thq-flex-column">
              <h2 className="content-list1-heading1 thq-heading-2">
                {props.heading1}
              </h2>
              <p className="thq-body-small">{props.content1}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading2}</h3>
              <p className="thq-body-small">{props.content2}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading3}</h3>
              <p className="thq-body-small">{props.content3}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading4}</h3>
              <p className="thq-body-small">{props.content4}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading5}</h3>
              <p className="thq-body-small">{props.content5}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading6}</h3>
              <p className="thq-body-small">{props.content6}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

ContentList1.defaultProps = {
  content4:
    'Generate detailed reports to analyze your financial habits and make informed decisions. Visualize your financial data for better understanding.',
  content3:
    'Set achievable financial goals and track your progress towards them. AlgoFunds helps you stay motivated and focused on your objectives.',
  content6:
    'Optimize your finances effortlessly with AlgoFunds. Take control of your money management and achieve financial stability.',
  heading5: 'Smart Money Decisions',
  content1:
    'Easily track your expenses and see where your money is going. AlgoFunds allows you to categorize your expenses for better insights.',
  content2:
    'Set up budgets for different categories and track your spending against them. Stay on top of your financial goals with our budget management feature.',
  heading4: 'Advanced Reports',
  content5:
    'Make smarter money decisions with the help of AlgoFunds. Our tool provides insights and suggestions to optimize your finances effortlessly.',
  heading1: 'Expense Tracking',
  heading3: 'Financial Goal Setting',
  heading6: 'Effortless Finance Optimization',
  heading2: 'Budget Management',
}

ContentList1.propTypes = {
  content4: PropTypes.string,
  content3: PropTypes.string,
  content6: PropTypes.string,
  heading5: PropTypes.string,
  content1: PropTypes.string,
  content2: PropTypes.string,
  heading4: PropTypes.string,
  content5: PropTypes.string,
  heading1: PropTypes.string,
  heading3: PropTypes.string,
  heading6: PropTypes.string,
  heading2: PropTypes.string,
}

export default ContentList1
