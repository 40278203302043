import React from 'react'

import { Helmet } from 'react-helmet'

import CTA1 from '../components/cta1'
import ContentList1 from '../components/content-list1'
import Navbar from "../components/Navbar/Navbar";
import './terms.css'
import Footer from '../components/Footer/Footer';

const Terms = (props) => {
  return (
    <div className="terms-container">
      <Helmet>
        <title>Terms - AlgoFunds</title>
        <meta property="og:title" content="Terms - AlgoFunds" />
      </Helmet>
      <Navbar></Navbar>
      <div className="terms-cta2">
        <CTA1></CTA1>
      </div>
      <div className="terms-contentlist3">
        <ContentList1></ContentList1>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Terms
