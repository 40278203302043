import React from 'react'

import { Helmet } from 'react-helmet'

import ContactForm3 from '../components/contact-form3'
import Contact14 from '../components/contact14'
import Contact7 from '../components/contact7'
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import './contact.css'

const Contact = (props) => {
  return (
    <div className="contact-container">
      <Helmet>
        <title>Contact </title>
        <meta
          property="og:title"
          content="Contact"
        />
      </Helmet>
      <Navbar></Navbar>
      <div className="contact-contactform2">
        <ContactForm3></ContactForm3>
      </div>
      <div className="contact-contact3">
        <Contact14></Contact14>
      </div>
      <div className="contact-contact4">
        <Contact7></Contact7>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Contact
