import React from "react";

import PropTypes from "prop-types";

import "./team3.css";

const Team3 = (props) => {
  return (
    <div className="thq-section-padding">
      <div className="team3-max-width thq-section-max-width">
        <div className="team3-section-title">
          <span className="thq-body-small">{props.content1}</span>
          <div className="team3-content">
            <h2 className="thq-heading-2">{props.heading1}</h2>
            <p className="thq-body-large team3-text02">{props.content2}</p>
          </div>
        </div>
        <div className="team3-content1">
          <div className="team3-content2">
            <div className="team3-row">
              <div className="team3-card">
                <img
                  alt={props.member1Alt}
                  src={props.member1Src}
                  className="thq-img-ratio-1-1 team3-member-image"
                />
                <div className="team3-content3">
                  <div className="team3-title">
                    <span className="team3-text03 thq-body-small">
                      {props.member1}
                    </span>
                    <span className="thq-body-small">{props.member1Job}</span>
                  </div>
                  <span className="team3-text05 thq-body-small">
                    {props.member1Content}
                  </span>
                </div>
                <div className="team3-social-icons">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="thq-icon-small"
                  >
                    <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
                  </svg>
                  <svg
                    viewBox="0 0 950.8571428571428 1024"
                    className="thq-icon-small"
                  >
                    <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                  </svg>
                </div>
              </div>
              <div className="team3-card1">
                <img
                  alt={props.member2Alt}
                  src={props.member2Src}
                  className="thq-img-ratio-1-1 team3-member-image1"
                />
                <div className="team3-content4">
                  <div className="team3-title1">
                    <span className="team3-text06 thq-body-small">
                      {props.member2}
                    </span>
                    <span className="thq-body-small">{props.member2Job}</span>
                  </div>
                  <span className="team3-text08 thq-body-small">
                    {props.member2Content}
                  </span>
                </div>
                <div className="team3-social-icons1">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="thq-icon-small"
                  >
                    <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
                  </svg>
                  <svg
                    viewBox="0 0 950.8571428571428 1024"
                    className="thq-icon-small"
                  >
                    <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                  </svg>
                </div>
              </div>
              <div className="team3-card2">
                <img
                  alt={props.member3Alt}
                  src={props.member3Src}
                  className="thq-img-ratio-1-1 team3-member-image2"
                />
                <div className="team3-content5">
                  <div className="team3-title2">
                    <span className="team3-text09 thq-body-small">
                      {props.member3}
                    </span>
                    <span className="thq-body-small">{props.member3Job}</span>
                  </div>
                  <span className="team3-text11 thq-body-small">
                    {props.member3Content}
                  </span>
                </div>
                <div className="team3-social-icons2">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="thq-icon-small"
                  >
                    <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
                  </svg>
                  <svg
                    viewBox="0 0 950.8571428571428 1024"
                    className="thq-icon-small"
                  >
                    <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                  </svg>
                </div>
              </div>
              <div className="team3-card3">
                <img
                  alt={props.member4Alt}
                  src={props.member4Src}
                  className="thq-img-ratio-1-1 team3-member-image3"
                />
                <div className="team3-content6">
                  <div className="team3-title3">
                    <span className="team3-text12 thq-body-small">
                      {props.member4}
                    </span>
                    <span className="thq-body-small">{props.member4Job}</span>
                  </div>
                  <span className="team3-text14 thq-body-small">
                    {props.member4Content}
                  </span>
                </div>
                <div className="team3-social-icons3">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="thq-icon-small"
                  >
                    <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
                  </svg>
                  <svg
                    viewBox="0 0 950.8571428571428 1024"
                    className="thq-icon-small"
                  >
                    <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="team3-content7">
            <div className="team3-content8">
              <h2 className="thq-heading-2">{props.heading2}</h2>
              <span className="team3-text16 thq-body-small">
                {props.content3}
              </span>
            </div>
            <button className="team3-button thq-button-filled">
              <span className="team3-text17 thq-body-small">
                {props.actionContent}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

Team3.defaultProps = {
  actionContent: "Open positions",
  content1:
    "Our team at AlgoFunds is dedicated to helping you achieve your financial goals and make informed decisions about your money.",
  content2: "Join our team and help us shape the future of finance!",
  content3: "Interested in working with us?",
  heading1: "Meet Our Team",
  heading2: "We’re hiring!",
  member1: "Nikky Amresh",
  member1Alt: "Image of Nikky Amresh",
  member1Content:
    "Nikky leads our product development and tech team. He is passionate about creating innovative financial tools that help our users make informed decisions.",
  member1Job: "Product and Tech Lead",
  member1Src:
    "https://media.licdn.com/dms/image/D4D03AQHCM1_4nmPIrA/profile-displayphoto-shrink_200_200/0/1686643907228?e=2147483647&v=beta&t=3xWnhZASMLwRXavJqWP44FaykT77d4UHmr1QQY3G5pI",
  member2: "Siddharth Arora",
  member2Alt: "Image of Siddharth Arora",
  member2Content:
    "Siddharth is our operations lead who ensures that our platform runs smoothly and efficiently. He is passionate about creating a seamless user experience for our users.",
  member2Job: "Operations Lead",
  member2Src:
    "https://cdn-icons-png.freepik.com/256/3135/3135715.png?semt=ais_hybrid",
  member3: "Priyanka Bhardwaj",
  member3Alt: "Image of Priyanka Bhardwaj",
  member3Content:
    "Priyanka is a senior software engineer who leads our tech team. She is passionate about creating innovative financial tools that help our users make informed decisions.",
  member3Job: "Senior Software Engineer",
  member3Src: "https://avatars.githubusercontent.com/u/69056618?v=4",
  member4: "Anushree Baghwar",
  member4Alt: "Image of Anushree Baghwar",
  member4Content:
    "Anushree is a UI/UX designer who leads our design team. She is passionate about creating intuitive and user-friendly interfaces that help our users make informed decisions.",
  member4Job: "UI/UX Designer",
  member4Src:
    "https://img.freepik.com/premium-vector/new-woman-avatar-icon-flat-illustration-woman-avatar-vector-icon-any-web-design_98396-3382.jpg",
};

Team3.propTypes = {
  actionContent: PropTypes.string,
  content1: PropTypes.string,
  content2: PropTypes.string,
  content3: PropTypes.string,
  heading1: PropTypes.string,
  heading2: PropTypes.string,
  member1: PropTypes.string,
  member1Alt: PropTypes.string,
  member1Content: PropTypes.string,
  member1Job: PropTypes.string,
  member1Src: PropTypes.string,
  member2: PropTypes.string,
  member2Alt: PropTypes.string,
  member2Content: PropTypes.string,
  member2Job: PropTypes.string,
  member2Src: PropTypes.string,
  member3: PropTypes.string,
  member3Alt: PropTypes.string,
  member3Content: PropTypes.string,
  member3Job: PropTypes.string,
  member3Src: PropTypes.string,
  member4: PropTypes.string,
  member4Alt: PropTypes.string,
  member4Content: PropTypes.string,
  member4Job: PropTypes.string,
  member4Src: PropTypes.string,
};

export default Team3;
