import React from 'react'

import { Helmet } from 'react-helmet'

import Hero2 from '../components/hero2'
import Stats1 from '../components/stats1'
import Testimonial16 from '../components/testimonial16'
import Features23 from '../components/features23'
import Team3 from '../components/team3'
import Navbar from "../components/Navbar/Navbar";
import './about.css'
import Footer from '../components/Footer/Footer'

const About = (props) => {
  return (
    <div className="about-container">
      <Helmet>
        <title>About</title>
        <meta property="og:title" content="About" />
      </Helmet>
      <Navbar></Navbar>
      <div className="about-hero2">
        <Hero2></Hero2>
      </div>
      <div className="about-stats3">
        <Stats1></Stats1>
      </div>
      {/* <div className="about-testimonial4">
        <Testimonial16></Testimonial16>
      </div> */}
      <div className="about-features5">
        <Features23></Features23>
      </div>
      <div className="about-team6">
        <Team3></Team3>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default About
